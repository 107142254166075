import React from 'react';
import { Menu, Breadcrumb } from 'element-react';
import { HashRouter as Router, Route, Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'
import * as action from '../../store/action'
import './index.css';
import Group from '../Category'
import Commodity from '../Commodity'
import CommodityCreate from '../Commodity/Create'
import Order from '../Order'
import Detail from '../Order/detail'
import Login from '../Login'
import Banner from '../Banner'

const SideNav = withRouter(({ setTitle, history }) => {
  return (
    <div className="App">
      <Menu theme="dark" defaultActive="2" className="el-menu-vertical-demo" onSelect={index => history.push(index)}>
        <Menu.SubMenu index="1" title={<span><i className="el-icon-menu"></i>商品</span>}>
          <Menu.Item index="/commodity/manage"> 商品管理 </Menu.Item>
          <Menu.Item index="/commodity/group"> 商品分组 </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu index="2" title={<span><i className="el-icon-message"></i>订单</span>}>
          <Menu.Item index="/order/list"> 订单列表 </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu index="3" title={<span><i className="el-icon-setting"></i>设置</span>}>
          <Menu.Item index="/setting/banner"> 商店横幅 </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </div>
  );
})


const ConTitle = ({ titles }) => {
  let items;
  if (typeof titles === "string") {
    items = [{
      title: titles
    }]
  } else {
    items = titles
  }
  return <Breadcrumb className="App-content-title" separator="/">
    {items.map((item, index) => <Breadcrumb.Item key={index}>{
      item.to ? <Link to={item.to}>{item.title}</Link> : item.title
    }</Breadcrumb.Item>)}
  </Breadcrumb>
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      contTitle: "aaaaaa"
    }
  }

  async componentWillMount() {

    window.App = this
    this.props.dispatch(action.setShopId("5ce3e2cd8db3f57b17f072c8"))
    await this.props.dispatch(action.fetchCategory())

  }

  async componentDidMount() {
    await this.props.dispatch(action.fetchShop())
  }
  render() {
    return (
      <Router className="App-container">
        <Helmet>
          <title>商城管理后台</title>
        </Helmet>
        {this.props.auth.data.isLogin && <div className="App-side-nav">
          <h2 className="App-title">商城管理后台</h2>
          <SideNav setTitle={this.setTitle} />
        </div>}
        {this.props.auth.data.isLogin && <div className="App-content">
          {/* <h1 className="App-content-title">{this.state.contTitle}</h1> */}
          <ConTitle titles={this.state.contTitle} />
          <Route path="/commodity/group" render={() => <Group setTitle={() => this.setState({ contTitle: "商品分组" })} />} />
          <Route path="/commodity/manage" render={() => <Commodity setTitle={title => this.setState({ contTitle: title || "商品管理" })} />} />
          <Route path="/commodity/create" render={(props) => <CommodityCreate route={props} setTitle={title => this.setState({ contTitle: title || [{ title: "商品管理", to: "/commodity/manage" }, { title: "创建商品" }] })} />} />
          <Route path="/commodity/edit/:id?" render={(props) => <CommodityCreate route={props} setTitle={title => this.setState({ contTitle: title || [{ title: "商品管理", to: "/commodity/manage" }, { title: "修改商品" }] })} />} />
          <Route path="/order/list" render={(props) => <Order route={props} setTitle={() => this.setState({ contTitle: "订单列表" })} />} />
          <Route path="/order/detail/:id?" render={(props) => <Detail route={props} setTitle={() => this.setState({ contTitle: "订单详情" })} />} />
          <Route path="/setting/banner" render={() => <Banner setTitle={() => this.setState({ contTitle: "横幅设置" })} />} />
          <Route exact path="/" render={() => <Redirect to={'/commodity/manage'} />} />
        </div>}
        {!this.props.auth.data.isLogin && <Login />}
      </Router>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => {
  return {
    shop: state.shop,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(App);
