import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Select, Pagination, Loading, Radio } from 'element-react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import * as action from '../../store/action'
import store from '../../store'
import './index.css'

class AddressDialog extends React.Component {
    constructor(props) {
        super(props)
    }

    async sendGood() {

        await store.dispatch(action.sendGood({
            id: this.props.data.id,
            expressCompanyCode: this.props.data.expressCompanyCode,
            expressCode: this.props.data.expressCode
        }))
        this.props.handleExpressChange({ visible: false })
    }

    render() {
        let data = this.props.data
        const options = [
            {
                value: 'ZTO',
                label: '中通快递'
            },
            // {
            //     value: 'SF',
            //     label: '顺丰快递'
            // }
        ]

        return <Dialog
            title={"快递单号修改"}
            visible={data.visible}
            onCancel={() => this.props.handleExpressChange({ visible: false })}
        >
            <Form>
                <Dialog.Body>
                    <Form.Item label="快递公司" labelWidth="80" >
                        <Select value={data.expressCompanyCode} placeholder="请选择" onChange={val => this.props.handleExpressChange({ expressCompanyCode: val })}>
                            {options.map((el => <Select.Option key={el.value} label={el.label} value={el.value} />))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="快递单号" labelWidth="80">
                        <Input value={data.expressCode} onChange={val => this.props.handleExpressChange({ expressCode: val })}></Input>
                    </Form.Item>
                </Dialog.Body>

                <Dialog.Footer className="dialog-footer">
                    <Button onClick={() => this.props.handleExpressChange({ visible: false })}>取 消</Button>
                    <Button type="primary" onClick={this.sendGood.bind(this)} >确 定</Button>
                </Dialog.Footer>
            </Form>

        </Dialog>
    }
}

class OrderItemContentGood extends React.Component {
    render() {
        let data = this.props.data
        return <div className="order-item-content-good">
            <div style={{ backgroundImage: `url(${data.good && data.good.covers && data.good.covers.length && data.good.covers[0]})` }} className="order-item-content-good-image"></div>
            <div className="order-item-content-good-item">
                <span className="order-item-content-good-item-name"
                    onClick={() => { window.open(`https://h5.shop.tot-magic.com/#/good?id=${data.goodId}`, "_blank"); }}
                >{data.good && data.good.name}</span>
                {data.skuId && data.sku && <span>规格：{data.sku.combines.map(val => val.value).join(" / ")}</span>}
            </div>
            <div className="order-item-content-good-right">
                <span>价格：{(((data.skuId && data.sku && data.sku.price) || (data.good && data.good.price) || 0) * 0.01).toFixed(2)}</span>
                <span>件数：{data.count}</span>
            </div>
        </div>
    }
}
const OrderItem = ({ data, history, detail, handleExpressChange, refund }) => {
    let statusMap = {
        'CANCELED': '自动取消',
        'PAYING': '买家未付款',
        'PAYED': '买家已付款',
        'SENDING': '发货中',
        'CONFIRMED': '订单已完成'
    }

    let expressClick = () => {
        handleExpressChange && handleExpressChange({
            id: data.id,
            expressCompanyCode: data.expressCompanyCode,
            expressCode: data.expressCode,
            visible: true
        })
    }

    return <div className="order-item">
        <div className="order-item-bar">
            <span className="order-item-bar-item">订单号：{data.id}</span>
            <span className="order-item-bar-item">下单时间：{new Date(data.orderDate * 1000).toLocaleString()}</span>
            {detail && <Button className="order-item-bar-item" type="text" size="small" style={{ marginLeft: "auto" }} onClick={() => { history.push(`/order/detail/${data.id}`) }}>查看详情</Button>}
            {refund && <Button className="order-item-bar-item" type="text" size="small" style={{ marginLeft: "auto" }} onClick={refund}>退款</Button>}
        </div>
        <div className="order-item-content">
            <div className="order-item-content-left">{data && data.orderGoods && data.orderGoods.map((item, key) => <OrderItemContentGood data={item} key={key} />)}</div>
            <div className="order-item-content-item">
                {data.address && <span>地区：{data.address.area}<br /></span>}
                {data.address && <span>详细地址：{data.address.detail}<br /></span>}
                {data.address && <span>联系人：{data.address.contact}<br /></span>}
                {data.address && <span>联系电话：{data.address.phone}<br /></span>}
            </div>
            <div className="order-item-content-item">
                实付款： {(data && data.fee * 0.01).toFixed(2)}
            </div>
            <div className="order-item-content-item">
                {data.status === 'PAYED' || data.status === 'SENDING' ?
                    <span className={handleExpressChange && "shop-admin-link"} onClick={expressClick}>快递单号</span>
                    : "快递单号"}
                {data.expressCode}
            </div>
            <div className="order-item-content-item">
                交易状态：{statusMap[data.status] || data.status}
            </div>
        </div>
    </div>
}

class Order extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            express: {
                id: '',
                expressCompanyCode: '',
                expressCode: '',
                visible: false
            }
        }
        this.handleExpressChange = this.handleExpressChange.bind(this)
    }

    handleExpressChange(express) {
        this.setState({
            express: {
                ...this.state.express,
                ...express
            }
        })
    }

    render() {
        let data = this.props.data
        if (data instanceof Array) {
            return <div>
                {data.map((val, idx) => <OrderItem history={this.props.history} data={val} detail={this.props.detail} key={idx} handleExpressChange={this.handleExpressChange} />)}
                <AddressDialog data={this.state.express} handleExpressChange={this.handleExpressChange} />
            </div>
        } else {
            return <div>
                <OrderItem history={this.props.history} data={data} detail={this.props.detail}  refund={this.props.refund} handleExpressChange={this.handleExpressChange} />
                <AddressDialog data={this.state.express} handleExpressChange={this.handleExpressChange} />
            </div>
        }
    }
}

export {
    Order
}

class OrderList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageSize: 8,
            status: ""
        }
    }

    async componentDidMount() {
        window.Order = this
        this.props.setTitle()
        await this.props.dispatch(action.fetchOrders(0, this.state.pageSize))
    }

    async changePage(page) {
        await this.props.dispatch(action.fetchOrders((page - 1) * this.state.pageSize, this.state.pageSize, this.state.status))
    }

    onChange(status) {
        this.setState({ status });
        this.props.dispatch(action.fetchOrders(0, this.state.pageSize, status))
    }

    render() {
        let history = this.props.route.history
        let statusMap = {
            'CANCELED': '自动取消',
            'PAYING': '买家未付款',
            'PAYED': '买家已付款',
            'SENDING': '发货中',
            'CONFIRMED': '订单已完成'
        }
        return <div className="order-list-container">
            <Helmet>
                <title>订单列表</title>
            </Helmet>
            <div className="order-filter">
                <Radio value="" checked={this.state.status === ""} onChange={this.onChange.bind(this)}>全部显示</Radio>
                {Object.keys(statusMap).map(item => <Radio value={item} checked={this.state.status === item} onChange={this.onChange.bind(this)} key={item}>{statusMap[item]}</Radio>)}
            </div>
            {this.props.isFetching ? <Loading text="拼命加载中">
                {this.props.orders && <Order history={history} data={this.props.orders} detail />}
            </Loading>
                : this.props.orders && <Order history={history} data={this.props.orders} detail />}
            <Pagination className="order-pagination" layout="prev, pager, next" pageSize={this.state.pageSize} total={this.props.total} onCurrentChange={this.changePage.bind(this)} />
        </div>
    }
}

OrderList.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    return {
        orders: state.orders && state.orders.item || [],
        total: state.orders && state.orders.total || 0,
        isFetching: state.orders && state.orders.isFetching,
    }
}

export default connect(mapStateToProps)(OrderList);
