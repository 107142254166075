import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Alert } from 'element-react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'
import * as action from '../../store/action'
import './index.css'


class Category extends React.Component {
    constructor(props) {
        super(props);
        window.Category = this
        this.state = {
            columns: [
                {
                    label: "分组名称",
                    prop: "name",
                    width: 180
                },
                // {
                //     label: "商品数",
                //     prop: "num",
                //     width: 180
                // },
                {
                    label: "最后修改时间",
                    prop: "date"
                },
                {
                    label: "顺序权值",
                    prop: "rank"
                },
                {
                    label: "操作",
                    width: 100,
                    render: ((row, column, index) => {
                        return <span>
                            <Button type="text" size="small" onClick={this.editRow.bind(this, index)}>编辑</Button>
                            <Button type="text" size="small" onClick={this.deleteRow.bind(this, index)}>删除</Button>
                        </span>
                    })
                }
            ],
            dialogVisible: false,
            form: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
    }

    async componentDidMount() {
        this.props.setTitle()
        await this.props.dispatch(action.fetchCategory())
    }

    deleteRow(index) {
        MessageBox.confirm('此操作将永久删除该分组, 是否继续?', '提示', {
            type: 'warning'
        }).then(() => {
            this.props.dispatch(action.deleteCategory(this.props.category[index].id))
                .then(res => {
                    if (res) {
                        Message({
                            type: 'success',
                            message: '删除成功!'
                        })
                    } else {
                        Message({
                            type: 'error',
                            message: '删除失败!'
                        })
                    }
                })
        }).catch(() => {
            Message({
                type: 'info',
                message: '已取消删除'
            });
        });
    }

    editRow(index) {
        this.setState({
            dialogVisible: true,
            form: {
                id: this.props.category[index].id,
                name: this.props.category[index].name,
                rank: this.props.category[index].rank
            }
        })
    }

    async saveCategory(id = "") {
        if (!this.state.form.name) {

            return;
        }
        this.setState({ dialogVisible: false })
        this.state.form.rank = Number(this.state.form.rank)
        await this.props.dispatch(action.saveCategory(this.state.form))
        this.setState({
            form: {
                id: '',
                name: ''
            }
        });
    }

    handleChange(property, val) {
        let form = this.state.form
        form[property] = val
        this.setState({ form: form });
    }

    addCatogry() {
        if (this.props.category.length < 5)
            this.setState({ dialogVisible: true, form: { rank: 1 } })
        else Message({
            type: 'warning',
            message: '最多只能同时拥有5个分组'
        })
    }

    render() {
        const { category } = this.props
        return <div className="Group-container">
            <Helmet>
                <title>商品分组</title>
            </Helmet>
            <div className="Group-container-top">
                <Button type="primary" onClick={this.addCatogry.bind(this)} >新建商品分组</Button>
            </div>
            <Table
                style={{ width: '100%' }}
                columns={this.state.columns}
                data={category}
                stripe={true}
            />
            <Dialog
                title={(this.state.form.id ? "修改商品分组" : "新建商品分组") + '(最多只支持5个商品分组)'}
                visible={this.state.dialogVisible}
                onCancel={() => this.setState({ dialogVisible: false })}
            >
                <Dialog.Body>
                    <Form model={this.state.form}>
                        <Form.Item label="分组名称" labelWidth="80">
                            <Input value={this.state.form.name} onChange={this.handleChange.bind(this, "name")}></Input>
                        </Form.Item>
                        <Form.Item label="排序权值" labelWidth="80">
                            <Input value={this.state.form.rank} type="number" onChange={this.handleChange.bind(this, "rank")}></Input>
                        </Form.Item>
                    </Form>
                </Dialog.Body>

                <Dialog.Footer className="dialog-footer">
                    <Button onClick={() => this.setState({ dialogVisible: false })}>取 消</Button>
                    <Button type="primary" onClick={this.saveCategory}>确 定</Button>
                </Dialog.Footer>
            </Dialog>
        </div>
    }
}

Category.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    let category = state.category.item || []
    category = category.map(item => ({
        ...item,
        date: new Date(item.modification * 1000).toLocaleString(),
        num: 10
    })).sort((a, b) => b.rank - a.rank)

    return {
        shop: state.shop,
        category: category,
    }
}

export default connect(mapStateToProps)(Category);