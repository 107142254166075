import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Select, Upload, Checkbox, Switch, Radio } from 'element-react';
import { connect } from 'react-redux'
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import Editor from "../../component/Editor";
import PropTypes from 'prop-types'
import * as action from '../../store/action'
import './index.css'


const UploadVideo = ({ videoUrl, update }) => {

    const onScucess = (res, file) => {
        videoUrl = res
        update(videoUrl)
    }

    const onPreview = file => {
        console.log(file)
        window.open(file.response || file.url, "_blank");
    }

    return (
        <Upload
            className="upload-demo"
            drag
            action="https://api.tot-magic.com/v1/qiniu/upload?type=VIDEO"
            accept="video/*"
            onSuccess={(res, file) => onScucess(res, file)}
            fileList={videoUrl ? [{ name: "商品视频，如若想上传新的视频，请先点击右边的删除", url: videoUrl }] : []}
            onPreview={file => onPreview(file)}
            tip={<div className="el-upload__tip">只能上传一个视频，如已有视频请删除后再上传</div>}
            limit={1}
            onRemove={(file, fileList) => { videoUrl = ''; update(videoUrl) }}
        >
            <i className="el-icon-upload"></i>
            <div className="el-upload__text">将视频拖到此处，或<em>点击上传</em></div>
        </Upload>
    )
}

const UploadPic = ({ fileList, update }) => {
    return (
        <Upload
            action="https://api.tot-magic.com/v1/qiniu/upload?type=IMAGE"
            accept="image/*"
            onRemove={(file, fl) => update(fl.map(val => val.response))}
            fileList={fileList.map((val, idx) => ({ name: `图片${idx}`, url: val }))}
            listType="picture"
            onSuccess={(url, file, fl) => update(fl.map(val => val.response))}
        >
            <Button size="small" type="primary">点击上传</Button>
        </Upload>
    )
}

const CommodityAttritubes = ({ commodityAttritubes, update, updateCommoditySku }) => {
    const addAttritubes = () => {
        let attr = [...commodityAttritubes, {
            name: "",
            values: [""]
        }]
        update(attr)
    }

    const deleteAttritubes = index => {
        commodityAttritubes.splice(index, 1)
        update(commodityAttritubes)
    }

    const addAttritubesValue = index => {
        commodityAttritubes[index].values.push("")
        update(commodityAttritubes)
    }

    const deleteAttritubesValue = (index, key) => {
        commodityAttritubes[index].values.splice(key, 1)
        update(commodityAttritubes)
    }

    const handleAttritubeNameChange = (index, val) => {
        commodityAttritubes[index].name = val
        update(commodityAttritubes)
    }

    const handleAttritubeValChange = (index, key, val) => {
        commodityAttritubes[index].values[key] = val
        update(commodityAttritubes)
    }

    return <div className="commodity-attritubes"> {(commodityAttritubes.map((item, index) => (
        <div className="commodity-attritubes-item" key={index}>
            <Form.Item label="规格名：">
                <Input className="" value={item.name} onChange={handleAttritubeNameChange.bind(null, index)} />
            </Form.Item>
            <Form.Item className="commodity-attritubes-values" label="规格值：">
                {item.values.map((val, key) => (
                    <div className="commodity-attritubes-values-item" key={key}>
                        <Input value={val} onChange={handleAttritubeValChange.bind(null, index, key)} />
                        <i className="el-icon-circle-close" onClick={deleteAttritubesValue.bind(null, index, key)}></i>
                    </div>
                ))}
            </Form.Item>
            <i className="el-icon-circle-close" onClick={deleteAttritubes.bind(null, index)}></i>
            <Button size="small" type="text" onClick={addAttritubesValue.bind(null, index)}>添加规格值</Button>
        </div>
    )))}
        <div className="commodity-attritubes-bottom">
            <Button size="small" type="primary" onClick={addAttritubes}>添加规格项目</Button>
            <Button size="small" type="primary" onClick={updateCommoditySku}>更新规格明细</Button>
        </div>
    </div>

}

const CommoditySku = ({ commoditySku, commodityAttritubes, update }) => {

    function onChange(key, val) {
        if (key === "price") {
            if (/^(\d+\.?(\d{0,2}))?$/.test(val)) {
                this[key] = val
            }
        } else {
            this[key] = val
        }
        update({ commoditySku: commoditySku })
    }

    let tableData = {
        columns: [
            {
                label: `规格项(${commodityAttritubes.map(val => val.name).join(" / ")})`,
                prop: "combines",
                render: (row, column, index) => {
                    return row.combines.map(val => val.value).join(" / ")
                }
            },
            {
                label: "价格",
                prop: "price",
                width: 120,
                render: (row, column, index) => {
                    return <Input type="number" value={row.price} size="small" onChange={onChange.bind(row, "price")}></Input>
                }
            },
            {
                label: "库存",
                prop: "inventory",
                width: 120,
                render: (row) => {
                    return <Input type="number" value={row.inventory} size="small" onChange={onChange.bind(row, "inventory")}></Input>
                }
            },
        ],
        data: [...commoditySku]
    }
    return <div className="commodity-sku">
        <Table
            style={{ width: '100%' }}
            columns={tableData.columns}
            data={tableData.data}
        />
    </div>
}

class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goodId: '',
            form: {
                name: '',
                categoryIds: [],
                description: '',
                content: '',
                inventory: 0,
                price: 0,
                productNumber: "",
                rank: 0,
                virtual: false
            },
            commodityAttritubes: [],
            commoditySku: [],
            covers: [],
            video: ""
        };
        this.setGood = this.setGood.bind(this)
    }


    componentWillMount() {
        window.Create = this
        this.props.setTitle()
    }

    setGood() {
        let good = this.props.good
        this.setState({
            goodId: good.id,
            form: {
                name: good.name,
                categoryIds: good.categoryIds,
                description: good.description,
                content: good.content,
                inventory: good.inventory,
                price: good.price,
                productNumber: good.productNumber,
                rank: good.rank,
                virtual: good.virtual
            },
            covers: good.covers,
            video: good.video,
            commoditySku: good.skus,
            commodityAttritubes: good.attritubes
        })
    }

    async componentDidMount() {
        if (this.props.id) {
            await this.props.dispatch(action.fetchGood(this.props.id))
        }
        if (!this.props.id) return;
        this.setGood()
        this.setState({ fetchSuccess: true })
        console.log(this.state)
        this.refs.editor.initialContent(this.state.form.content);
    }

    onSubmit(e) {
        e.preventDefault();
    }

    onChange(key, value) {
        if (key === "price") {
            if (/^(\d+\.?(\d{0,2}))?$/.test(value))
                this.state.form[key] = value
            else return;
        }
        else this.state.form[key] = value;
        this.setState({ form: this.state.form })
    }

    update(val) {
        this.setState({
            commodityAttritubes: val
        })
    }

    // 将规格属性转换为规格表
    static attritubes2Sku(attr) {
        attr = attr.filter(val => val.values.filter(item => item).length)
        if (attr.length === 0) {
            return []
        }
        if (attr.length === 1) {
            return attr[0].values.filter(val => val).map(val => [{ name: attr[0].name, value: val }])
        } else {
            let idxArr = attr[0].values
            let subAttr = [...attr]
            subAttr.splice(0, 1)
            let res = []
            let subRes = this.attritubes2Sku(subAttr)
            for (let item of idxArr) {
                if (item === "") continue;
                res = res.concat(subRes.map(value => [{
                    name: attr[0].name,
                    value: item
                }, ...value]))
            }
            return res;
        }
    }
    updateCommoditySku() {
        let skuCombines = Create.attritubes2Sku(this.state.commodityAttritubes)
        let oldSku = this.state.commoditySku
        let newSku = skuCombines.map(item => {
            let idx = oldSku.findIndex(sku => {
                return JSON.stringify(sku.combines) === JSON.stringify(item)
            })
            if (idx >= 0) {
                return oldSku[idx]
            } else {
                return {
                    combines: item,
                    price: 0,
                    inventory: 0,
                    skuNumber: ""
                }
            }
        })
        this.setState({
            commoditySku: newSku
        })
    }

    static string2penny(str) {
        str = String(str)
        if (!str.includes("."))
            return Number(str + "00")
        let tmp = str.length - str.indexOf(".")
        let format = ""
        switch (tmp) {
            case 1: format = str + "00"; break;
            case 2: format = str + "0"; break;
            case 3: format = str; break;
            default: format = str + ".00"
        }
        return Number(format.replace('.', ''))
    }

    saveGood() {
        let goodInput = {
            attritubes: this.state.commodityAttritubes,
            covers: this.state.covers,
            video: this.state.video,
            ...this.state.form,
            offline: (this.props.id && this.props.good.offline) || false
        }
        goodInput.price = Create.string2penny(goodInput.price)
        goodInput.inventory = Number(goodInput.inventory)
        goodInput.rank = Number(goodInput.rank)
        if (this.state.goodId) {
            goodInput.id = this.state.goodId
        }
        let skuInputs = JSON.parse(JSON.stringify(this.state.commoditySku))
        skuInputs.forEach(item => {
            item.price = Create.string2penny(item.price)
            item.inventory = Number(item.inventory)
        });
        this.props.dispatch(action.saveGood(goodInput, skuInputs))
            .then(
                Message({
                    type: 'success',
                    message: '保存成功!'
                })
            )
    }


    render() {
        return <div className="Commodity-create-container">
            <Helmet>
                <title>商品管理</title>
            </Helmet>
            <Form className="Commodity-create-form" model={this.state.form} labelWidth="80" onSubmit={this.onSubmit.bind(this)}>
                <Form.Item label="商品名称">
                    <Input value={this.state.form.name} onChange={this.onChange.bind(this, 'name')}></Input>
                </Form.Item>
                <Form.Item label="商品价格">
                    <Input value={this.state.form.price} onChange={this.onChange.bind(this, 'price')} ></Input>
                </Form.Item>
                <Form.Item label="商品库存">
                    <Input value={this.state.form.inventory} onChange={this.onChange.bind(this, 'inventory')} type="number"></Input>
                </Form.Item>
                <Form.Item label="商品编号">
                    <Input value={this.state.form.productNumber} onChange={this.onChange.bind(this, 'productNumber')} ></Input>
                </Form.Item>
                <Form.Item label="排序权值">
                    <Input value={this.state.form.rank} onChange={this.onChange.bind(this, 'rank')} type="number"></Input>
                </Form.Item>
                <Form.Item label="虚拟商品">
                    <Switch value={this.state.form.virtual} onChange={this.onChange.bind(this, 'virtual')} />
                </Form.Item>
                <Form.Item label="商品分组">
                    <Checkbox.Group value={this.state.form.categoryIds} onChange={this.onChange.bind(this, 'categoryIds')}>
                        {this.props.category.map(val => <Checkbox label={val.name} name="type" value={val.id} key={val.id}></Checkbox>)}
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item label="分享描述">
                    <Input type="textarea" value={this.state.form.description} onChange={this.onChange.bind(this, 'description')}></Input>
                </Form.Item>
                <Form.Item label="商品图片">
                    <div id="commodity-covers-container">
                        {this.props.id === "" || this.state.fetchSuccess ? <UploadPic fileList={this.state.covers} update={val => this.setState({ covers: val })} /> : ""}
                    </div>
                </Form.Item>
                <Form.Item label="商品视频">
                    <div id="commodity-video-container">
                        {this.props.id === "" || this.state.fetchSuccess ? <UploadVideo videoUrl={this.state.video} update={val => this.setState({ video: val })} /> : ""}

                    </div>
                </Form.Item>
                <Form.Item label="商品规格">
                    <CommodityAttritubes commodityAttritubes={this.state.commodityAttritubes}
                        update={this.update.bind(this)} updateCommoditySku={this.updateCommoditySku.bind(this)} />
                </Form.Item>
                {this.state.commoditySku.length > 0 &&
                    <Form.Item className="commodity-sku-item" label="规格明细">
                        <CommoditySku commoditySku={this.state.commoditySku} commodityAttritubes={this.state.commodityAttritubes} update={val => this.setState(val)} />
                    </Form.Item>
                }
                <Form.Item label="详情页面" className="Commodity-create-form-content" >
                    {/* <Input value={this.state.form.content} onChange={this.onChange.bind(this, 'content')} ></Input> */}
                    <Editor
                        ref={"editor"}
                        initialContent={this.state.form.content}
                        onHTMLChange={html => {
                            this.state.form.content = html;
                        }}
                    />
                </Form.Item>
                <Form.Item className="Commodity-create-form-save">
                    <Button type="primary" onClick={this.saveGood.bind(this)}>保存</Button>
                    <Button>取消</Button>
                </Form.Item>
            </Form>
        </div>
    }
}

Create.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    let id = props.route.match.params.id || ""
    let good = state.good.data
    if (good) {
        good = JSON.parse(JSON.stringify(good))
        good.price = (good.price * 0.01).toFixed(2)
        good.skus.forEach(item => {
            item.price = (item.price * 0.01).toFixed(2)
        });
    }
    return {
        id: id,
        shop: state.shop,
        category: state.category.item || [],
        good: good
    }
}

export default connect(mapStateToProps)(Create);