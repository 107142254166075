import { GraphQLClient } from 'graphql-request'
const endpoint = 'https://graphql.tot-magic.com/graphql'
const client = new GraphQLClient(endpoint, { headers: {} })

const actionType = {
    SET_SHOP_ID: "SET_SHOP_ID",
    SHOP: {
        FETCH_REQUEST: "SHOP_FETCH_REQUEST",
        FETCH_FAILURE: "SHOP_FETCH_FAILURE",
        FETCH_SUCCESS: "SHOP_FETCH_SUCCESS"
    },
    GROUP: {
        FETCH_REQUEST: "GROUP_FETCH_REQUEST",
        FETCH_FAILURE: "GROUP_FETCH_FAILURE",
        FETCH_SUCCESS: "GROUP_FETCH_SUCCESS"
    },
    GOOD: {
        FETCH_REQUEST: "GOOD_FETCH_REQUEST",
        FETCH_FAILURE: "GOOD_FETCH_FAILURE",
        FETCH_SUCCESS: "GOOD_FETCH_SUCCESS"
    },
    GOODS: {
        FETCH_REQUEST: "GOODS_FETCH_REQUEST",
        FETCH_FAILURE: "GOODS_FETCH_FAILURE",
        FETCH_SUCCESS: "GOODS_FETCH_SUCCESS"
    },
    OFFLINEGOODS: {
        FETCH_REQUEST: "OFFLINEGOODS_FETCH_REQUEST",
        FETCH_FAILURE: "OFFLINEGOODS_FETCH_FAILURE",
        FETCH_SUCCESS: "OFFLINEGOODS_FETCH_SUCCESS"
    },
    ORDERS: {
        FETCH_REQUEST: "ORDERS_FETCH_REQUEST",
        FETCH_FAILURE: "ORDERS_FETCH_FAILURE",
        FETCH_SUCCESS: "ORDERS_FETCH_SUCCESS"
    },
    ORDER: {
        FETCH_REQUEST: "ORDER_FETCH_REQUEST",
        FETCH_FAILURE: "ORDER_FETCH_FAILURE",
        FETCH_SUCCESS: "ORDER_FETCH_SUCCESS"
    },
    AUTH: {
        FETCH_REQUEST: "AUTH_FETCH_REQUEST",
        FETCH_SUCCESS: "AUTH_FETCH_SUCCESS"
    },
    BANNER: {
        FETCH_REQUEST: "BANNER_FETCH_REQUEST",
        FETCH_SUCCESS: "BANNER_FETCH_SUCCESS"
    },
    FETCH_REQUEST: "FETCH_REQUEST",
    FETCH_FAILURE: "FETCH_FAILURE",
    FETCH_SUCCESS: "FETCH_SUCCESS"
}

const setShopId = id => ({
    type: actionType.SET_SHOP_ID,
    id: id
})

const fetchShop = () => (dispatch, getState) => {
    dispatch({ type: actionType.SHOP.FETCH_REQUEST });
    let query = `
                query findStoreById($id:String) {
                    findStoreById(id: $id) {
                    name
                    }
                }
            `
    let variables = {
        id: getState().shopId,
    }
    return client.request(query, variables)
        .then(data => dispatch({
            type: actionType.SHOP.FETCH_SUCCESS,
            data: {
                ...data.findStoreById
            }
        }))
}

const fetchCategory = () => (dispatch, getState) => {
    dispatch({ type: actionType.GROUP.FETCH_REQUEST });
    let query = `
                query findCategoriesByStoreId($id: String){
                    findCategoriesByStoreId(storeId: $id){
                        id
                        name
                        modification
                        rank
                    }
                }
            `
    let variables = {
        id: getState().shopId,
    }
    return client.request(query, variables)
        .then(data => dispatch({
            type: actionType.GROUP.FETCH_SUCCESS,
            data: {
                item: data.findCategoriesByStoreId
            }
        }))
}

// 重名处理  挂起
const saveCategory = CategoryInput => (dispatch, getState) => {
    dispatch({ type: actionType.GROUP.FETCH_REQUEST });
    let query = `
                mutation saveCategory($input: CategoryInput){
                    saveCategory(input:$input){
                        id
                        name
                        modification
                        rank
                    }
                }
            `
    let variables = {
        input: {
            storeId: getState().shopId,
            ...CategoryInput
        }
    }

    return client.request(query, variables)
        .then(data => {
            return dispatch({
                type: actionType.GROUP.FETCH_SUCCESS,
                data: {
                    // 将原来状态中的相同id的item去掉，之后加入新的
                    item: getState().category.item.filter(val => val.id !== data.saveCategory.id).concat(data.saveCategory)
                }
            })
        })
}

const deleteCategory = id => (dispatch, getState) => {
    dispatch({ type: actionType.GROUP.FETCH_REQUEST });
    let query = `
                mutation deleteCategoryById($id: String) {
                    deleteCategoryById(id: $id) 
                }
            `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => {
            if (res.deleteCategoryById) {
                dispatch({
                    type: actionType.GROUP.FETCH_SUCCESS,
                    data: {
                        // 将原来状态中的相同id的item去掉，之后加入新的
                        item: getState().category.item.filter(val => val.id !== id)
                    }
                })
            }
            return res.deleteCategoryById
        })
}

const fetchGoods = (offline = false) => (dispatch, getState) => {
    dispatch({ type: offline ? actionType.OFFLINEGOODS.FETCH_REQUEST : actionType.GOODS.FETCH_REQUEST })
    let query = `
                query findGoodsByStoreId($storeId: String, $offline: Boolean) {
                    findGoodsByStoreId(storeId: $storeId, offline: $offline) {
                        id
                        name
                        covers
                        video
                        description
                        categoryIds
                        content
                        productNumber
                        price
                        inventory
                        rank
                        modification
                        soldCount
                        offline
                        attritubes {
                            name
                            values
                        }
                        skus {
                            id
                            price
                            inventory
                            combines {
                                name
                                value
                            }
                        }
                    }
                }
            `
    let variables = {
        storeId: getState().shopId,
        offline: offline
    }
    return client.request(query, variables)
        .then(res => dispatch({
            type: offline ? actionType.OFFLINEGOODS.FETCH_SUCCESS : actionType.GOODS.FETCH_SUCCESS,
            data: {
                item: res.findGoodsByStoreId
            }
        }))
}


const fetchGood = (id) => (dispatch, getState) => {
    dispatch({ type: actionType.GOOD.FETCH_REQUEST })
    let query = `
                query findGoodById($id: String) {
                    findGoodById(id: $id) {
                        id
                        name
                        covers
                        video
                        description
                        categoryIds
                        content
                        productNumber
                        price
                        inventory
                        rank
                        modification
                        soldCount
                        offline
                        virtual
                        attritubes {
                            name
                            values
                        }
                        skus {
                            id
                            price
                            inventory
                            combines {
                                name
                                value
                            }
                        }
                    }
                }
            `
    let variables = {
        id
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.GOOD.FETCH_SUCCESS,
            data: {
                data: res.findGoodById
            }
        }))
}

const saveGood = (goodInput, skuInputs) => (dispatch, getState) => {
    dispatch({ type: actionType.GOODS.FETCH_REQUEST })
    let query = `
                mutation saveGood($goodInput: GoodInput, $skuInputs: [SkuInput]) {
                    saveGood(goodInput: $goodInput, skuInputs: $skuInputs) {
                        id
                        name
                        covers
                        video
                        description
                        categoryIds
                        content
                        productNumber
                        price
                        inventory
                        rank
                        modification
                        soldCount
                        offline
                        virtual
                        attritubes {
                            name
                            values
                        }
                        skus {
                            id
                            price
                            inventory
                            combines {
                                name
                                value
                            }
                        }
                    }
                }
            `
    let variables = {
        goodInput: {
            storeId: getState().shopId,
            ...goodInput
        },
        skuInputs: skuInputs
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(data => dispatch({
            type: actionType.GOODS.FETCH_SUCCESS,
            data: {
                item: getState().goods.item ? getState().goods.item.filter(val => val.id !== data.saveGood.id).concat(data.saveGood) : []
            }
        }))
}

const deleteGood = id => (dispatch, getState) => {
    dispatch({ type: actionType.GOODS.FETCH_REQUEST })
    dispatch({ type: actionType.OFFLINEGOODS.FETCH_REQUEST })
    let query = `
            mutation offlineGoodById($id: String) {
                offlineGoodById(id:$id){
                    id
                }
            }
        `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => {
            res.offlineGoodById && getState().offlineGoods.item && dispatch({
                type: actionType.OFFLINEGOODS.FETCH_SUCCESS,
                data: {
                    item: [...getState().offlineGoods.item, ...getState().goods.item.filter(val => val.id == id)]
                }
            })
            return res.offlineGoodById && dispatch({
                type: actionType.GOODS.FETCH_SUCCESS,
                data: {
                    // 将原来状态中的相同id的item去掉，之后加入新的
                    item: getState().goods.item.filter(val => val.id !== id)
                }
            })
        }
        )
}

const onlineGood = id => (dispatch, getState) => {
    dispatch({ type: actionType.GOODS.FETCH_REQUEST })
    dispatch({ type: actionType.OFFLINEGOODS.FETCH_REQUEST })
    let query = `
            mutation onlineGoodById($id: String) {
                onlineGoodById(id:$id){
                    id
                }
            }
        `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => {
            res.onlineGoodById && dispatch({
                type: actionType.GOODS.FETCH_SUCCESS,
                data: {
                    item: [...getState().goods.item, ...getState().offlineGoods.item.filter(val => val.id == id)]
                }
            })
            return res.onlineGoodById && dispatch({
                type: actionType.OFFLINEGOODS.FETCH_SUCCESS,
                data: {
                    item: getState().offlineGoods.item.filter(val => val.id !== id)
                }
            })
        }
        )
}

const fetchOrders = (offset = 0, limit = 10, status = "") => (dispatch, getState) => {
    dispatch({ type: actionType.ORDERS.FETCH_REQUEST })
    let query = `
                query findOrdersByStoreId($storeId: String, $offset: Int, $limit: Int, $status: String) {
                    findOrdersByStoreId(storeId: $storeId, offset: $offset, limit: $limit, status: $status) {
                        data{
                            id
                            userId
                            orderGoods{
                                goodId
                                good {
                                    name
                                    price
                                    covers
                                }
                                skuId
                                sku {
                                    id
                                    combines {
                                        name
                                        value
                                    }
                                    price
                                }
                                count
                            }
                            address{
                                id
                                userId
                                area
                                detail
                                contact
                                phone
                            }
                            status
                            orderDate
                            modification
                            expressCompanyCode
                            expressCode
                            fee
                            payPlatform
                        }
                        total
                    }
                }
            `
    let variables = {
        storeId: getState().shopId,
        offset: offset,
        limit: limit,
        status: status
    }
    return client.request(query, variables)
        // .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.ORDERS.FETCH_SUCCESS,
            data: {
                item: res.findOrdersByStoreId.data,
                total: res.findOrdersByStoreId.total
            }
        }))
}

const fetchOrder = (id) => (dispatch, getState) => {
    dispatch({ type: actionType.ORDER.FETCH_REQUEST })
    let query = `
                query findOrderById($id: String) {
                    findOrderById(id: $id) {
                        id
                        userId
                        orderGoods{
                            goodId
                            good {
                                name
                                price
                                covers
                            }
                            skuId
                            sku {
                                id
                                combines {
                                    name
                                    value
                                }
                                price
                            }
                            count
                        }
                        address{
                            id
                            userId
                            area
                            detail
                            contact
                            phone
                        }
                        fee
                        status
                        orderDate
                        modification
                        expressCompanyCode
                        expressCode
                        expressTraces{
                            acceptTime
                            acceptStation
                            remark
                        }
                        payPlatform
                    }
                }
            `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.ORDER.FETCH_SUCCESS,
            data: res.findOrderById
        }))
}

export const refund = (id) => (dispatch, getState) => {
    dispatch({ type: actionType.ORDER.FETCH_REQUEST })
    let query = `
                mutation refund($id: String){
                    refund(id: $id) {
                        id
                        status
                    }
                }
            `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.ORDER.FETCH_SUCCESS,
            data: {
                ...getState().order,
                ...res.refund
            }
        }))
}

const sendGood = input => (dispatch, getState) => {
    dispatch({ type: actionType.ORDER.FETCH_REQUEST })
    let query = `
        mutation sendGood($input: SendGoodInput){
            sendGood(input: $input){
                id
                expressCompanyCode
                expressCode
            }
        }
    `
    let variables = {
        input: input
    }
    return client.request(query, variables)
        .then(res => res.sendGood)
        .then(data => getState().orders.item.map(val => val.id === data.id ? { ...val, ...data } : val))
        .then(data => dispatch({
            type: actionType.ORDERS.FETCH_SUCCESS,
            data: {
                total: getState().orders.total,
                item: data
            }
        }))
}

const login = (username, pwd) => (dispatch, getState) => {
    dispatch({ type: actionType.AUTH.FETCH_REQUEST })
    let map = {
        'admin': '123456'
    }
    if (map[username] && map[username] === pwd) {
        dispatch({ type: actionType.AUTH.FETCH_SUCCESS, data: { isLogin: true } })
        return true
    }
    return false
}

const fetchBanners = () => (dispatch, getState) => {
    dispatch({ type: actionType.BANNER.FETCH_REQUEST })
    let query = `
                query findBannersByStoreId($storeId: String){
                    findBannersByStoreId(storeId: $storeId){
                        id
                        good {
                            id
                            name
                        }
                        rank
                        modification
                    }
                }
            `
    let variables = {
        storeId: getState().shopId,
    }
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.BANNER.FETCH_SUCCESS,
            data: res.findBannersByStoreId
        }))
}

const saveBanner = (goodId, rank, id) => (dispatch, getState) => {
    dispatch({ type: actionType.BANNER.FETCH_REQUEST })
    let query = `
                mutation input($input: BannerInput) {
                    saveBanner(input: $input){
                        id
                        good {
                            id
                            name
                        }
                        rank
                        modification
                    }
                }
            `
    let variables = {
        input: {
            storeId: getState().shopId,
            goodId,
            rank
        }
    }
    if (id) variables.input['id'] = id
    return client.request(query, variables)
        .then(res => { console.log(res); return res })
        .then(res => dispatch({
            type: actionType.BANNER.FETCH_SUCCESS,
            data: [
                ...getState().banner.data.filter(val => val.id !== id),
                res.saveBanner
            ]
        }))
}



const deleteBanner = id => (dispatch, getState) => {
    dispatch({ type: actionType.BANNER.FETCH_REQUEST });
    let query = `
                mutation deleteBannerById($id: String) {
                    deleteBannerById(id: $id)
                }
            `
    let variables = {
        id: id
    }
    return client.request(query, variables)
        .then(res => {
            if (res.deleteBannerById) {
                dispatch({
                    type: actionType.BANNER.FETCH_SUCCESS,
                    data: getState().banner.data.filter(val => val.id !== id)
                })
            }
            return res.deleteBannerById
        })
}

export {
    actionType,
    setShopId,
    fetchShop,
    fetchCategory,
    saveCategory,
    deleteCategory,
    fetchGoods,
    fetchGood,
    saveGood,
    deleteGood,
    fetchOrders,
    fetchOrder,
    sendGood,
    onlineGood,
    login,
    fetchBanners,
    saveBanner,
    deleteBanner
}