import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Tabs } from 'element-react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import * as action from '../../store/action'
import './index.css'


class Commodity extends React.Component {
    constructor(props) {
        super(props);
        window.Commodity = this
        const makeColums = offline => [
            {
                label: "id",
                prop: "id",
                width: 240
            },
            {
                label: "商品名称",
                prop: "name",
                width: 180
            },
            {
                label: "商品价格",
                prop: "price",
                width: 120,
                render: (item => (item.price * 0.01).toFixed(2))
            },
            {
                label: "库存",
                width: 90,
                render: (item => item.skus && item.skus.length > 0 ? item.skus.reduce((pv, cv) => pv += cv.inventory, 0) : item.inventory)
            },
            {
                label: "销量",
                prop: "soldCount",
                width: 90
            },
            {
                label: "顺序权值",
                prop: "rank",
                width: 100
            },
            {
                label: "最后修改时间",
                render: (item => new Date(item.modification * 1000).toLocaleString())
            },
            {
                label: "操作",
                width: 100,
                render: ((row, column, index) => {
                    return <span>
                        <Button type="text" size="small"><Link to={`/commodity/edit/${row.id}`}>编辑</Link></Button>
                        <Button type="text" size="small" onClick={this.deleteRow.bind(this, row.id, offline)}>{offline ? '上架' : '下架'}</Button>
                    </span>
                })
            }
        ]
        this.state = {
            columns: makeColums(false),
            dialogVisible: false,
            form: {},
            offLineColums: makeColums(true)
        }

        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        this.props.setTitle()
        await this.props.dispatch(action.fetchGoods(false))
    }

    async loadOfflineGoods(val, val2) {
        if (val.key === '.1' && this.props.offlineGoods === undefined) {
            await this.props.dispatch(action.fetchGoods(true))
        }
    }

    deleteRow(id, offline) {
        console.log(id)
        MessageBox.confirm(`此操作将${offline ? '上架' : '下架'}该商品, 是否继续?`, '提示', {
            type: 'warning'
        }).then(() => {
            this.props.dispatch(offline ? action.onlineGood(id) : action.deleteGood(id))
                .then(res => {
                    console.log(res)
                    if (res) {
                        Message({
                            type: 'success',
                            message: `${offline ? '上架' : '下架'}成功!`
                        })
                    } else {
                        Message({
                            type: 'error',
                            message: `${offline ? '上架' : '下架'}失败!`
                        })
                    }
                })
        }).catch(() => {
            Message({
                type: 'info',
                message: '已取消操作'
            });
        });
    }


    handleChange(property, val) {
        let form = this.state.form
        form[property] = val
        this.setState({ form: form });
    }

    render() {
        const { goods, offlineGoods } = this.props
        return <div className="Commodity-container">
            <Helmet>
                <title>商品管理</title>
            </Helmet>
            <div className="Commodity-container-top">
                <Button type="primary" >
                    <Link to="/commodity/create">发布商品</Link>
                </Button>
            </div>
            <Tabs type="card" value="1" onTabClick={this.loadOfflineGoods.bind(this)}>
                <Tabs.Pane label="销售商品" name="1">
                    <Table
                        style={{ width: '100%' }}
                        columns={this.state.columns}
                        data={goods}
                        stripe={true}
                    />
                </Tabs.Pane>
                <Tabs.Pane label="下线商品" name="2">
                    <Table
                        style={{ width: '100%' }}
                        columns={this.state.offLineColums}
                        data={offlineGoods}
                        stripe={true}
                    />
                </Tabs.Pane>
            </Tabs>
            <Dialog
                title={this.state.form.id ? "修改商品分组" : "新建商品分组"}
                visible={this.state.dialogVisible}
                onCancel={() => this.setState({ dialogVisible: false })}
            >
                <Dialog.Body>
                    <Form model={this.state.form}>
                        <Form.Item label="分组名称" labelWidth="80">
                            <Input value={this.state.form.name} onChange={this.handleChange.bind(this, "name")}></Input>
                        </Form.Item>
                        <Form.Item label="排序权值" labelWidth="80">
                            <Input value={this.state.form.rank} type="number" onChange={this.handleChange.bind(this, "rank")}></Input>
                        </Form.Item>
                    </Form>
                </Dialog.Body>

                <Dialog.Footer className="dialog-footer">
                    <Button onClick={() => this.setState({ dialogVisible: false })}>取 消</Button>
                    <Button type="primary" onClick={this.saveCategory}>确 定</Button>
                </Dialog.Footer>
            </Dialog>
        </div>
    }
}

Commodity.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    let category = state.category.item || []
    category = category.map(item => ({
        ...item,
        date: new Date(item.modification * 1000).toLocaleString(),
        num: 10
    })).sort((a, b) => b.rank - a.rank)

    return {
        shop: state.shop,
        category: category,
        goods: state.goods.item,
        offlineGoods: state.offlineGoods.item
    }
}

export default withRouter(connect(mapStateToProps)(Commodity));