import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Notification } from 'element-react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import * as action from '../../store/action'
import './index.css'
import { Order } from './index'

class Detail extends React.Component {
    constructor(props) {
        super(props)
        this.props.setTitle()
        this.state = {
            columns: [
                {
                    label: "时间",
                    prop: "acceptTime",
                    width: 180
                }, 
                {
                    label: "快递详情",
                    prop: "acceptStation",
                }
            ],
            data: []
        }
    }

    componentWillMount() {
        this.props.dispatch(action.fetchOrder(this.props.id))
    }

    refund = () => {
        MessageBox.confirm(`此操作将退款, 是否继续?`, '提示', {
            type: 'warning'
        }).then(() => {
            return this.props.dispatch(action.refund(this.props.id))
        }).then((res) => {
            console.log(res)
            Notification({
                title: '成功',
                message: '退款成功',
                type: 'success'
              });
        })
    }

    render() {
        return <div className="order-detail-container">
            {!this.props.order.isFetching && <Order data={this.props.order} refund={this.props.order.status === "PAYED" ? this.refund : null}/>}
            <Table
                style={{ width: '100%' }}
                columns={this.state.columns}
                maxHeight={200}
                data={this.props.order.expressTraces || []} />
        </div>
    }
}

const mapStateToProps = (state, props) => {
    let id = props.route.match.params.id || ""
    return {
        id: id,
        order: state.order
    }
}

Detail.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(Detail);