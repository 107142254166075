import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Card } from 'element-react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import * as action from '../../store/action'
import './index.css'
import { Order } from './index'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentWillMount(){
        let username = sessionStorage.getItem('username')
        let pwd = sessionStorage.getItem('pwd')
        this.props.dispatch(action.login(username, pwd))
    }

    async login() {
        let res = await this.props.dispatch(action.login(this.state.username, this.state.pwd))
        if (res) {
            sessionStorage.setItem('username', this.state.username)
            sessionStorage.setItem('pwd', this.state.pwd)
            Message('登录成功');
        } else {
            Message('账户或者密码错误');
        }
    }

    render() {
        return <div className="login-container">
            <div className="login-logo">
                {/* 橘子树Logo */}
            </div>
            <Card
                className="login-card"
                header={
                    <div className="login-card-title">
                        <span>橘子树商场后台管理系统</span>
                    </div>
                }
            >
                <Form labelWidth="60" >
                    <Form.Item label="账户">
                        <Input value={this.state.username} onChange={username => this.setState({ username })}></Input>
                    </Form.Item>
                    <Form.Item label="密码">
                        <Input type={"password"} value={this.state.pwd} onChange={pwd => this.setState({ pwd })}></Input>
                    </Form.Item>
                    <Form.Item>
                        <Button className="login-btn" type="primary" onClick={this.login.bind(this)}>登录</Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    }
}

const mapStateToProps = (state, props) => {
    return {
    }
}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

export default connect(mapStateToProps)(Login);