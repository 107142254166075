import React from 'react';
import { Button, Table, Dialog, Form, Input, MessageBox, Message, Alert, AutoComplete } from 'element-react';
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'
import * as action from '../../store/action'
import './index.css'


class Banner extends React.Component {
    constructor(props) {
        super(props);
        window.Banner = this
        this.state = {
            columns: [
                {
                    label: "商品名称",
                    render: ((row, column, index) => {
                        return <span>{row.good && row.good.name}</span>
                    })
                },
                {
                    label: "最后修改时间",
                    prop: "date"
                },
                {
                    label: "顺序权值",
                    width: 180,
                    prop: "rank"
                },
                {
                    label: "操作",
                    width: 100,
                    render: ((row, column, index) => {
                        return <span>
                            <Button type="text" size="small" onClick={this.editRow.bind(this, index)}>编辑</Button>
                            <Button type="text" size="small" onClick={this.deleteRow.bind(this, index)}>删除</Button>
                        </span>
                    })
                }
            ],
            dialogVisible: false,
            addValue: {},
            rank: 1,
            id: '',
            form: {}
        }

        this.saveBanner = this.saveBanner.bind(this);
    }

    async componentDidMount() {
        this.props.setTitle()
        try {
            await this.props.dispatch(action.fetchBanners())
            await this.props.dispatch(action.fetchGoods(false))
        } catch (err) {
            console.log(err)
        }
    }

    deleteRow(index) {
        MessageBox.confirm('此操作将删除该横幅, 是否继续?', '提示', {
            type: 'warning'
        }).then(() => {
            this.props.dispatch(action.deleteBanner(this.props.banner[index].id))
                .then(res => {
                    if (res) {
                        Message({
                            type: 'success',
                            message: '删除成功!'
                        })
                    } else {
                        Message({
                            type: 'error',
                            message: '删除失败!'
                        })
                    }
                })
        }).catch(() => {
            Message({
                type: 'info',
                message: '已取消删除'
            });
        });
    }

    editRow(index) {
        this.setState({
            dialogVisible: true,
            addValue: this.props.banner[index].good,
            rank: this.props.banner[index].rank,
            id: this.props.banner[index].id
        })
    }

    async saveBanner() {
        if (JSON.stringify(this.state.addValue) === '{}') {
            return;
        }
        this.setState({ dialogVisible: false })
        let res = await this.props.dispatch(action.saveBanner(this.state.addValue.id, Number(this.state.rank), this.state.id))
    }

    addBanner() {
        this.setState({
            dialogVisible: true,
            addValue: {},
            rank: 1,
            id: '',
        })
    }


    querySearch(queryString, cb) {
        const goods = this.props.goods;
        if (!queryString || goods.findIndex(val => val.name === queryString) >= 0)
            return cb(goods);
        const results = goods.filter(item => item.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
        cb(results);
    }

    handleSelect(item) {
        this.setState({ addValue: item })
    }

    render() {
        return <div className="Group-container">
            <Helmet>
                <title>横幅设置</title>
            </Helmet>
            <div className="Group-container-top">
                <Button type="primary" onClick={this.addBanner.bind(this)} >新增横幅项</Button>
            </div>
            <Table
                style={{ width: '100%' }}
                columns={this.state.columns}
                data={this.props.banner}
                stripe={true}
            />
            <Dialog
                title={"选择增横对应商品"}
                visible={this.state.dialogVisible}
                onCancel={() => this.setState({ dialogVisible: false })}
            >
                <Dialog.Body>
                    <Form>
                        <Form.Item label="对应商品" labelWidth="80">
                            <AutoComplete
                                style={{ width: '100%' }}
                                icon="edit"
                                placeholder="请输入内容"
                                value={this.state.addValue && this.state.addValue.name}
                                fetchSuggestions={this.querySearch.bind(this)}
                                customItem={({ index, item }) => <span key={index}>{item.name}</span>}
                                onSelect={this.handleSelect.bind(this)}
                            ></AutoComplete>
                        </Form.Item>
                        <Form.Item label="排序权值" labelWidth="80">
                            <Input value={this.state.rank} type="number" onChange={rank => this.setState({ rank })}></Input>
                        </Form.Item>
                    </Form>
                </Dialog.Body>

                <Dialog.Footer className="dialog-footer">
                    <Button onClick={() => this.setState({ dialogVisible: false })}>取 消</Button>
                    <Button type="primary" onClick={this.saveBanner}>确 定</Button>
                </Dialog.Footer>
            </Dialog>
        </div>
    }
}

Banner.propTypes = {
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = (state, props) => {
    let banner = state.banner.data
        .map(val => ({ ...val, date: new Date(val.modification * 1000).toLocaleString() }))
        .sort((a, b) => b.rank - a.rank)
    return {
        shop: state.shop,
        goods: state.goods.item || [],
        banner: banner
    }
}

export default connect(mapStateToProps)(Banner);