import React from "react";
import BraftEditor from "braft-editor";
import { upload } from "../utils/request";
import 'braft-editor/dist/index.css'

export default class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editorState: BraftEditor.createEditorState(null)
        }
    }

    initialContent(content) {
        this.setState({
            editorState: BraftEditor.createEditorState(content)
        })
    }

    render() {
        return ( <
            BraftEditor value = { this.state.editorState }
            onChange = {
                e => {
                    this.setState({ editorState: e });
                    this.props.onHTMLChange(this.state.editorState.toHTML())
                }
            }
            media = { this.editorMediaProps() }
            />
        );
    }

    editorMediaProps() {
        return {
            'media.accepts': {
                image: 'image/png,image/jpeg,image/gif,image/webp,image/apng,image/svg',
                // video: 'video/mp4'
            },
            uploadFn: param => {
                const image = param.file.type.startsWith('image');
                upload(param.file, image ? 'IMAGE' : 'VIDEO')
                    .then(url => {
                        param.success({ url: url });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    }
}