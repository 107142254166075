

export function upload(file, type) {
    const formData = new FormData();
    formData.append('file', file);
    return fetch(`https://api.tot-magic.com/v1/qiniu/upload?type=${type}`, {
        method: 'POST',
        mode: 'cors',
        body: formData
    }).then(response => response.text());
}