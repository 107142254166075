import { combineReducers, createStore, applyMiddleware } from 'redux';
import { actionType } from './action';
import thunk from 'redux-thunk';

const shopId = (state = "", action) => {
    switch (action.type) {
        case actionType.SET_SHOP_ID:
            return action.id
        default:
            return state;
    }
}

const shop = (state = { id: "", isFetching: false }, action) => {
    switch (action.type) {
        case actionType.SHOP.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.SHOP.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const category = (state = [], action) => {
    switch (action.type) {
        case actionType.GROUP.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.GROUP.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const goods = (state = {}, action) => {
    switch (action.type) {
        case actionType.GOODS.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.GOODS.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const good = (state = {}, action) => {
    switch (action.type) {
        case actionType.GOOD.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.GOOD.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const offlineGoods = (state = {}, action) => {
    switch (action.type) {
        case actionType.OFFLINEGOODS.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.OFFLINEGOODS.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const orders = (state = {}, action) => {
    switch (action.type) {
        case actionType.ORDERS.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.ORDERS.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, action.data)
        default:
            return state;
    }
}

const order = (state = {}, action) => {
    switch (action.type) {
        case actionType.ORDER.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.ORDER.FETCH_SUCCESS:
            return Object.assign({}, state, action.data, { isFetching: false })
        default:
            return state;
    }
}

const auth = (state = { isFetching: false, data: { isLogin: false } }, action) => {
    switch (action.type) {
        case actionType.AUTH.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.AUTH.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, { data: action.data })
        default:
            return state;
    }
}

const banner = (state = { isFetching: false, data: [] }, action) => {
    switch (action.type) {
        case actionType.BANNER.FETCH_REQUEST:
            return Object.assign({}, state, { isFetching: true })
        case actionType.BANNER.FETCH_SUCCESS:
            return Object.assign({}, state, { isFetching: false }, { data: action.data })
        default:
            return state;
    }
}

const reducer = combineReducers({ shopId, shop, category, goods, offlineGoods, good, orders, order, auth, banner });

const preloadedState = {
    shop: {},
    category: {},
    goods: {},
    offlineGoods: {},
    good: {},
    orders: {},
    order: {},
    auth: { isFetching: false, data: { isLogin: false } },
    banner: { isFetching: false, data: [] },
}
const store = createStore(reducer, preloadedState, applyMiddleware(thunk));

export default store;
